
if(window.location.search.length > 0 ){
    let params = window.location.search.substring(1);
    params = params.replace(/(\[\]\=)/g, '-').split('&');
    console.log(params);


    $.each($('.filters input'), function(){
        if(!(params.indexOf($(this).attr('id')) > -1) ){
          $(this).prop('checked', false)
        }
    })

}

$(".filter-reset button").on('click', function(){
    let $filters = $(this).parents(".widget").find('.filters input');

    if( $(this).hasClass('add')){
       $.each($filters, function(){
           $(this).prop('checked', true);
       });
    }else{
        $.each($filters, function(){
            $(this).prop('checked', false);
        });
    };

    // /1?cat[]=2&cat[]=3
});

$('.submit-filter').on('click', function(){

let $cat = $('.filters .cat:checked');
let $sex = $('.filters .sex:checked');

let $catSelected = [];
let $sexSelected = [];
let url = '/resultats/1';

$.each($cat, function(){
    $catSelected.push($(this).attr('id').replace('cat-', ''));
});

$.each($sex, function(){
    $sexSelected.push($(this).attr('id').replace('sex-', ''));
});

if($catSelected.length > 0){
    $catSelected.forEach(function(e, i){
        i === 0 ? url+='?': url+='&';
        url+='cat[]='+e;
    })
}

if($sexSelected.length > 0){
    $sexSelected.forEach(function(e, i){
        if(i === 0) {
            $catSelected.length > 0 ? url+='&' : url+='?' ;
        }else{
            url+='&'
        }
        url+='sex[]='+e;
    })
}

window.location.href = url;

});

$('#infoMap .close').on('click', function () {
    $("#infoMap").addClass('no-display');
});